import { _ } from "../l10n.js";
export function getRejectReasons() {
    return {
        prefer_cash: _('Eine Barkaution ist mir lieber'),
        too_expensive: _('Das Angebot ist mir zu teuer'),
        dont_understand: _('Ich habe das Angebot nicht verstanden'),
        not_serious: _('Das Angebot wirkt unseriös'),
        other: _('Anderer Grund'),
    };
}
