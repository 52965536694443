import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {formatPrice} from 'shared/utils.js';
import {_} from 'shared/l10n.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import Input from '../../../components/Input.js';
import FormWrapper from '../../../components/FormWrapper.js';
import BottomNavigation from '../../../components/BottomNavigation.js';
import FormHeader from '../../../components/FormHeader.js';
import PromotionCard from '../../../components/PromotionCard.js';
import {isDevFormEnabled} from '../../../lib/utils.js';
import SecurityBanner from '../../../components/security_banner/SecurityBanner.js';

import styles from './AccountScreen.module.scss';

export default function AccountScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {
    monthly_fee_cents,
    tenant_promotion_campaign_header,
    tenant_promotion_campaign_body,
  } = contract_details;
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({token, fields, history, setError}),
  );

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.AccountScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  return (
    <FormWrapper>
      <div className={styles.container}>
        <div className={styles.content}>
          <FormHeader
            title={_('Konto für das Bürgschaftsentgelt')}
            subtitle={_(
              'Für Ihren Kautionsschutz berechnen wir ein Entgelt i. H. v. monatlich %1. Das Entgelt wird von dem unten angegebenen Konto abgebucht.',
              formatPrice(monthly_fee_cents),
            )}
          />

          <Input
            label={_('IBAN')}
            error={errors.iban?.message}
            {...register('iban')}
          />
        </div>

        <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />

        <PromotionCard
          header={tenant_promotion_campaign_header}
          body={tenant_promotion_campaign_body}
        />
        <SecurityBanner />
      </div>
    </FormWrapper>
  );
}

async function proceed({token, fields, history, setError}) {
  if (fields.iban) {
    fields.iban = fields.iban.replaceAll(' ', '');
  }

  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.account,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
