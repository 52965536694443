import { _ } from "../l10n.js";
export function getUserConsents() {
    return {
        manager_gdpr_tenant: {
            description: 'Der Mieter möchte ein Angebot für Mietkautionsbürgschaft von GetMomo erhalten',
            table_name: 'rental_contracts',
        },
        manager_claim: {
            description: 'Hiermit bestätigen wir die vertraglichen Rahmenbedingungen und AGBs',
            table_name: 'claims',
        },
        tenant_terms_and_conditions: {
            description: 'Agreed to terms and conditions',
            table_name: 'deposit_guarantee_applications',
            version: '2023-07-06',
        },
        tenant_itag_request: {
            description: _('Mit der Weiterleitung meiner Daten an die identity Trust Management AG zum Zwecke der Durchführung der Online-Legitimation bin ich einverstanden. Die AGB der identity Trust Management AG habe ich gelesen und akzeptiere diese hiermit.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_information_request: {
            description: _('Ich habe verstanden, dass die Bank Informationen über mich einholen kann.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_economic_interest: {
            description: _('Ich beantrage das Produkt für mich selbst und nicht auf Rechnung einer dritten Person.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_explanations_desired: {
            description: _('Ich habe die vorvertraglichen Vertragsinformationen der Bank verstanden und wünsche keine weitere Erläuterung.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_contract_discussion: {
            description: _('Die Vertragsinhalte wurden erörtert.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_consent_data_transmission: {
            description: _('Ich bestätige die Kenntnisnahme der Datenübermittlung an die SCHUFA und befreie die Bank insoweit vom Bankgeheimnis.'),
            table_name: 'deposit_guarantee_applications',
        },
        tenant_vvrb_consent_data_processing: {
            description: _('Ich bin ausdrücklich mit der Datenverarbeitung und der Datennutzung gemäß der AGB sowie der Datenschutzbestimmungen der VVRB einverstanden.'),
            table_name: 'deposit_guarantee_applications',
        },
        manager_terms_and_conditions: {
            description: 'Agreed to terms and conditions',
            table_name: 'managers',
            version: '2024-08-01',
        },
        manager_deposit_guarantee_conditions: {
            description: 'Agreed to special conditions',
            table_name: 'managers',
            version: '2023-07-06',
        },
        manager_cash_deposit_special_conditions: {
            description: 'Agreed to special conditions for collective deposit accounts',
            table_name: 'managers',
            version: '2024-08-01',
        },
        owner_banking_terms: {
            description: 'Per Klick auf „Weiter“ akzeptieren Sie die Allgemeinen Geschäftsbedingungen von GetMomo Financial GmbH und gelangen zur Kontoeröffnung.',
            table_name: 'property_owners',
            version: '2023-07-06',
        },
    };
}
