import React, {useState} from 'react';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import {formatPrice, formatIban, formatDate} from 'shared/utils.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {useForm} from 'react-hook-form';
import {setFormErrors, scrollToError} from 'shared-web/effects.js';
import {_} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';

import FormWrapper from '../../../components/FormWrapper.js';
import BottomNavigation from '../../../components/BottomNavigation.js';
import InvitationStepper from '../../../components/InvitationStepper.js';
import FormHeader from '../../../components/FormHeader.js';
import PromotionCard from '../../../components/PromotionCard.js';

import styles from './SummaryScreen.module.scss';

export default function SummaryScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const [is_loading, setIsLoading] = useState(false);
  const {handleSubmit, setError} = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({
      contract_details,
      token,
      history,
      fields,
      setError,
      setIsLoading,
    });
  });

  async function saveAndGoBack() {
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.SummaryScreen,
      contract_details,
    });
    history.push(previous_step, {
      contract_details: {...contract_details},
      token,
    });
  }

  const {
    has_second_tenant,
    tenants,
    deposit_amount_cents,
    monthly_fee_cents,
    iban,
    rental_contract_start_date,
    street_name,
    street_number,
    postal_code,
    region,
    tenant_promotion_campaign_header,
    tenant_promotion_campaign_body,
  } = contract_details;

  let tenant_display_names;
  has_second_tenant &&
    (tenant_display_names = `${tenants[0].first_name} & ${tenants[1].first_name}`);

  return (
    <FormWrapper tenant_display_name={tenant_display_names}>
      <FormHeader
        title={_('Zusammenfassung')}
        subtitle={_(
          'Hier finden Sie die wichtigsten Eckdaten. Nach dem Absenden der eingegebenen Daten, senden wir Ihnen Ihr persönliches Vertragsangebot zu.',
        )}
      />

      {has_second_tenant ? (
        <>
          <SummaryDetailsSection
            title={_('Mieter 1')}
            data={[
              {name: _('Name'), value: tenants[0].last_name},
              {name: _('Vorname'), value: tenants[0].first_name},
            ]}
          />
          <SummaryDetailsSection
            title={_('Mieter 2')}
            data={[
              {name: _('Name'), value: tenants[1].last_name},
              {name: _('Vorname'), value: tenants[1].first_name},
            ]}
          />
        </>
      ) : (
        <SummaryDetailsSection
          title={_('Mieterdaten')}
          data={[
            {name: _('Name'), value: tenants[0].last_name},
            {name: _('Vorname'), value: tenants[0].first_name},
          ]}
        />
      )}
      <SummaryDetailsSection
        className={styles.summary_details}
        title={_('Kautionsservice')}
        data={[
          {
            name: _('Höhe der abgedeckten Kaution'),
            value: formatPrice(deposit_amount_cents),
          },
          {
            name: _('Monatliches Entgelt *'),
            value: formatPrice(monthly_fee_cents),
          },
          {name: _('Konto'), value: formatIban(iban)},
          {
            name: _('Geplanter Starttermin'),
            value: getPlannedStartDateValue({rental_contract_start_date}),
          },
        ]}
      />
      <PromotionCard
        header={tenant_promotion_campaign_header}
        body={tenant_promotion_campaign_body}
      />
      <SummaryAddressSection
        street_name={street_name}
        street_number={street_number}
        postal_code={postal_code}
        region={region}
      />

      <BottomNavigation
        onSubmit={onSubmit}
        onBack={saveAndGoBack}
        is_last_step
        is_loading={is_loading}
      />

      <Footnotes />

      {has_second_tenant && <InvitationStepper step={3} />}
    </FormWrapper>
  );
}

function SummaryDetailsSection({title, data, className}) {
  return (
    <section className={classNames(styles.summary_section, className)}>
      <p className={text_styles.caption_right}>{title}</p>
      {data.map(({name, value}, idx) => (
        <div key={idx} className={styles.summary_details_section_entry}>
          <span className={text_styles.body2}>{name}</span>
          <span className={text_styles.body2_right_bold}>{value}</span>
        </div>
      ))}
    </section>
  );
}

function SummaryAddressSection({
  street_name,
  street_number,
  postal_code,
  region,
}) {
  return (
    <section className={styles.summary_section}>
      <p className={text_styles.caption_right}>{_('Adresse:')}</p>
      <p
        className={classNames([
          text_styles.body2,
          styles.summary_address_section_body,
        ])}>
        {_('%1 %2', street_name, street_number)}
        <br />
        {_('%1 %2', postal_code, region)}
      </p>
    </section>
  );
}

function Footnotes() {
  return (
    <section className={styles.footnotes}>
      <p className={classNames([text_styles.caption_left, styles.footnote])}>
        {_(
          '* Es kann zu einer Abweichung von wenigen Cents kommen. Die genaue und verbindliche Angabe finden Sie in Ihrem Vertrag.',
        )}
      </p>
    </section>
  );
}

async function proceed({
  contract_details,
  history,
  token,
  fields,
  setError,
  setIsLoading,
}) {
  setIsLoading(true);
  let next_step;

  try {
    await RPC('applyForContract', {
      token,
      ...fields,
    });
    ({next_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.SummaryScreen,
      contract_details: {...contract_details, ...fields},
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  } finally {
    setIsLoading(false);
  }

  history.push(next_step);
}

// https://github.com/get-momo/issues/issues/2643
function getPlannedStartDateValue({rental_contract_start_date}) {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  const start_date = new Date(rental_contract_start_date);

  return start_date.getTime() > today.getTime()
    ? formatDate(start_date)
    : _('so bald wie möglich');
}
